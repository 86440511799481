/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more_actions': {
    width: 10,
    height: 2,
    viewBox: '0 0 10 2',
    data: '<g _fill="#154F4A" fill-rule="evenodd"><path pid="0" d="M0 0h2v2H0zM4 0h2v2H4zM8 0h2v2H8z"/></g>'
  }
})
